import { AuthProviderEnvironment } from '@matillion/hub-client'

const version = process.env.REACT_APP_VERSION ?? '0.0.0-local'

export const getEnvFromUrl = (raw: string) => {
  const hostname = new URL(raw).hostname
    .replace('.eu1.', '.')
    .replace('.us1.', '.')

  const currentEnv = hostname
    .split('.')
    ?.reverse()?.[2]
    ?.split('-')
    ?.reverse()?.[0]

  if (['local', 'dev', 'preprod'].includes(currentEnv)) {
    return currentEnv
  } else {
    return 'prod'
  }
}

/*
If this is a production build, derive the environment from the URL.
If not (i.e. local or test context) honour the environment variable.
 */
const environment =
  process.env.NODE_ENV === 'production'
    ? getEnvFromUrl(window.location.href)
    : process.env.REACT_APP_ENVIRONMENT ?? 'local'

type EnvironmentConfig = {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  DATADOG?: Record<string, string>
  API_BASE_URL: string
  EOS_API_BASE_URL: string
  DGS_API_BASE_URL: string
  PROJECTS_API_BASE_URL: string
  API_BASE_AUDIENCE: string
  PLATFORM_AUDIENCE: string
  BILLING_API_BASE_URL: string
  LAUNCH_DARKLY: {
    CLIENT_ID: string
    FLAGS: { [key: string]: string | undefined }
  }
} & {
  [key: string]: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)

const config = {
  environment,
  version,

  authEnvironment: envConfig.AUTH_ENVIRONMENT,
  apiBaseUrl: envConfig.API_BASE_URL,
  eosApiBaseUrl: envConfig.EOS_API_BASE_URL,
  dgsApiBaseUrl: envConfig.DGS_API_BASE_URL,
  projectsApiBaseUrl: envConfig.PROJECTS_API_BASE_URL,
  apiBaseAudience: envConfig.API_BASE_AUDIENCE,
  platformAudience: envConfig.PLATFORM_AUDIENCE,
  billingApiBaseUrl: envConfig.BILLING_API_BASE_URL,

  gtmId: envConfig.GTM_ID,

  launchDarkly: {
    clientId: envConfig.LAUNCH_DARKLY.CLIENT_ID,
    flags: envConfig.LAUNCH_DARKLY.FLAGS
  },

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    site: envConfig.DATADOG.SITE,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    service: process.env.REACT_APP_DATADOG_APP_NAME as string,
    rumService: process.env.REACT_APP_DATADOG_APP_NAME as string,
    applicationId: process.env.REACT_APP_DATADOG_APP_ID as string
  }
}

if (process.env.NODE_ENV !== 'production') {
  config.launchDarkly.flags = Object.entries(config.launchDarkly.flags).reduce(
    (obj, [key, defaultValue]) => {
      const override = window.localStorage.getItem(`flag.${key}`)
      return {
        ...obj,
        [key]: override ? override === 'true' : defaultValue
      }
    },
    {}
  )
}

export default config
